import Vue from 'vue';
import axios from 'axios';
import * as Sentry from '@sentry/vue';

import { aclService, authService } from '@emobg/access-utils';
import {
  ENV as ENVIRONMENTS, LOG_TYPE, getValue, logger, navigationErrorHandler,
} from '@emobg/web-utils';
import Vuex from 'vuex';

import moment from 'moment';

import vuexI18n from 'vuex-i18n';

import VueCookie from 'vue-cookie';

import * as VueGoogleMaps from 'vue2-google-maps';

import AsyncComputed from 'vue-async-computed';

import { applyPolyfills, defineCustomElements } from '@emobg/web-components/loader';
import includes from 'lodash/includes';
import set from 'lodash/set';
import split from 'lodash/split';
import isNil from 'lodash/isNil';
import upperCase from 'lodash/upperCase';
import VueGtm from '@gtm-support/vue2-gtm';
import { utilsMixin } from '@/utils/utilsMixin';
import config from '@/config';
import { getRequestCode, removeRequestCode, saveRequestCodeUrl } from '@/helpers/costAllocation/costAllocationHelpers';
import { MuiFullScreenPlugin } from '@/components/motion-ui-legacy/MuiFullScreen';

import gtmPluginConfig from '@/plugins/gtm';
import {
  createLaunchDarklyInstance,
  destroyLaunchDarklyInstance,
  getFlags,
  getInstancePayload,
  toCamelCaseFlags,
} from '@/services/FeatureFlag';
import { getUserPersistedData, setUserPersistedData } from '@/helpers/user/tracking/trackingData';
import SharedRouteNames from '@Shared/router/routes-names';
import { useAuth } from '@/composable/Api/Auth/auth';
import languageService from '@/services/Language';

import AppView from './AppView';
import router from './router';
import Translations from './translations';

import componentOptionsMixin from './mixins/componentOptions';
import { handleAuthentication } from './handlers/Authentication';

saveRequestCodeUrl();

const loadSass = async () => import('@/assets/scss/themes/_index.scss');

authService.init({
  domain: config.data.auth0Domain,
  clientID: config.data.auth0ClientId,
  redirectUri: window.location.origin,
  audience: config.data.auth0Audience,
  responseType: 'token id_token',
  scope: 'openid',
  logoutCallback: () => {
    const requestCode = getRequestCode();

    if (requestCode) {
      removeRequestCode();
    }
  },
});

const webappVersion = require('../package.json').version;

Vue.observable(aclService);

require('./interceptors.js');

Vue.use(Vuex);
Vue.use(MuiFullScreenPlugin);
Vue.use(AsyncComputed);
Vue.use(VueGtm, gtmPluginConfig);
Vue.mixin(componentOptionsMixin);

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ui-/];

const { environment } = config.data;

const isVueDevtoolsEnabled = environment === ENVIRONMENTS.development || environment === ENVIRONMENTS.local;
if (isVueDevtoolsEnabled) {
  Vue.config.devtools = true;
}

const { default: store } = require('./vue/stores/Store');

Vue.use(vuexI18n.plugin, store, {
  onTranslationNotFound: (locale, key) => {
    if (includes(languageService.trackedLocales, locale)) {
      const message = `PhraseApp key "${key}" not found for locale "${locale}"`;
      logger.message(message, LOG_TYPE.warning);
      Sentry.captureMessage(message);
    }
  },
});

Vue.use(VueCookie);
Vue.mixin(utilsMixin);

const language = languageService.getLanguage();

moment.locale(language);
languageService.setForceLanguage(language);
Translations.setLanguages(languageService.availablesLocales, language);
Translations.setFallbackLanguage(languageService.defaultLang);

if (environment !== ENVIRONMENTS.local) {
  Sentry.init({
    dsn: 'https://9dde513131d3b9c3bfa18ebf5c9d5824@o274321.ingest.us.sentry.io/1516949',
    debug: environment !== ENVIRONMENTS.production,
    environment,
    release: `webapp@${webappVersion}`,
    ignoreErrors: [
      'Non-Error exception captured',
    ],
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    beforeSend(event, hint) {
      const msg = getValue(event, 'message', '');
      if (msg && msg.startsWith('Non-Error exception captured') && hint.originalException.error) {
        Sentry.withScope(scope => {
          scope.setExtra('nonErrorException', true);
          Sentry.captureException(hint.originalException.error);
        });
        return null;
      }
      return event;
    },
    attachStacktrace: true,
  });
}

Vue.use(VueGoogleMaps, {
  load: {
    key: config.data.googleMapsKey,
    libraries: ['places', 'geometry'],
  },
});

async function run() {
  const { clearCookies } = useAuth();

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const { country } = params;

  const featureFlagData = getInstancePayload({});

  const launchDarklyInstance = createLaunchDarklyInstance(featureFlagData);
  const flags = await getFlags(launchDarklyInstance);
  const featureFlags = toCamelCaseFlags(flags);

  const mustRedirectMaintenance = !isNil(featureFlags.migrationInProgressSignupRedirect)
    && includes(split(featureFlags.migrationInProgressSignupRedirect, ','), upperCase(country));

  destroyLaunchDarklyInstance();

  if (mustRedirectMaintenance) {
    router.push({ name: SharedRouteNames.maintenance }).catch(navigationErrorHandler);
  }

  try {
    if (!mustRedirectMaintenance) {
      const { isRedirecting } = await handleAuthentication();

      if (isRedirecting) {
        return;
      }
    }

    await axios.post(`${config.data.serverUrl}/middleware/token`, {
      accessToken: authService.instance.accessToken,
      domain: window.location.hostname,
      expires: 0.020,
    });
  } catch (error) {
    if (!includes(window.location.href, '/error')) {
      logger.message(error.message, LOG_TYPE.error);
      const userExtraMetadata = getValue(getUserPersistedData(), 'data', undefined);

      clearCookies();
      window.sessionStorage.clear();
      setUserPersistedData({ value: { ...userExtraMetadata } });
      window.location.replace('/error');
    }
  }

  await Promise.all([applyPolyfills(), loadSass()]);

  defineCustomElements();

  const app = new Vue({
    el: '#app',
    router,
    store,
    render: h => h(AppView),
  });

  if (isVueDevtoolsEnabled) {
    set(window, '__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue', getValue(app, 'constructor', undefined));
  }
}

run();

window.jquery = require('jquery');
